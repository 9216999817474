import React, { lazy } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import HomeLayout from 'src/layouts/HomeLayout';
import { PATH_PAGE } from './paths';

// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: '/',
      component: lazy(() => import('src/views/home/LandingPageView'))
    },
    {
      exact: true,
      path: '/forgot-password',
      component: lazy(() => import('src/views/home/ForgotPasswordView/ForgotPassword'))
    },
    {
      exact: true,
      path: '/password-reset/:email',
      component: lazy(() => import('src/views/home/ForgotPasswordView/ResetPassword'))
    },
    {
      exact: true,
      path: '/components',
      component: lazy(() => import('src/views/home/ComponentsView'))
    },
    {
      exact: true,
      path: '/PrivacyPolicy',
      component: lazy(() => import('src/views/home/LandingPageView/PrivacyPolicy'))
    },
    {
      exact: true,
      path: '/TermsCondition',
      component: lazy(() => import('src/views/home/LandingPageView/TermsCondition'))
    },
    {
      exact: true,
      path: '/ChangeLog',
      component: lazy(() => import('src/views/home/LandingPageView/ChangeLog'))
    },
    {
      exact: true,
      path: '/subdistributor/pre-order/view/:preOrderID',
      component: RedirPreOrder
    },

    // CUSTOM RESET PASSWORD
    {
      exact: true,
      path: '/form-reset-password',
      component: lazy(() => import('src/views/home/ForgotPasswordView/indexFormResetPassword'))
    },

    {
      component: () => <Redirect to="/404" />
    }

  ]
};

function RedirPreOrder() {
  const location = useLocation()
  localStorage.setItem('preOrderDirect', location.pathname)
  return <Redirect to={PATH_PAGE.auth.homepage}/>
}


export default HomeRoutes;
