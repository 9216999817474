import { sum, map, filter, uniqBy, sample } from 'lodash';
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
const domainURL = process.env.REACT_APP_DOMAIN;


const initialState = {
  isLoading: false,
  offerSerial: '',
  supplierOfferID: '',
  supplier: '',
  supplierID: '',
  expectedDeliveryType: 'month',
  expectedDelivery: '',
  openOfferDate: new Date(),
  submissionOfferDate: '',
  expiryOfferDate: '',
  offerTo: '',
  offerProducts: [],
  currAddProducts: [], // current - diff. background
  backFromAddProduct: false,
  // version 3
  categoryOptions: {
    plush: false,
    figurines: false,
    tcg: false
  },
  MOQ: 0,
  costingProfileName: '',
  costingProfileID: '',

  // NEW - HARI - ROS DESIGN 2
  costCurrency: '', // 
  costCurrencySymbol: '',
  pricingProfileName: '',
  pricingProfileID: '',
  baseCurrency: '', 
  baseCurrencySymbol: '',
  shippingTerms: '',
  shippingTermsID: '',
  paymentTerms: '',
  paymentTermsID: '',
  backFromEditProduct: false,
  editOfferID: '',
  tempForEditOfferProducts: [],
  deleteItemID: [],

  listSpecificRetailer: [],
  chosenRetailerGroup: [],

  offerName: '',
  isGashaponOffer: 'no',

  pricingDetail: {},
  priceOption: '',

  isSubDistributorOnlyOffer: 'no'
}

const slice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setOfferSerial(state, action) {
      state.isLoading = false;
      state.offerSerial = action.payload;
    },

    setOfferProducts(state, action) {
      state.isLoading = false;
      state.offerProducts = action.payload;
    },

    setCurrAddProducts(state, action) {
      state.isLoading = false;
      state.currAddProducts = action.payload;
    },

    setSupplierOfferID(state, action) {
      state.isLoading = false;
      state.supplierOfferID = action.payload;
    },

    setSupplier(state, action) {
      state.isLoading = false;
      state.supplier = action.payload;
    },

    setSupplierID(state, action) {
      state.isLoading = false;
      state.supplierID = action.payload;
    },

    setExpectedDeliveryType(state, action) {
      state.isLoading = false;
      state.expectedDeliveryType = action.payload;
    },

    setExpectedDelivery(state, action) {
      state.isLoading = false;
      state.expectedDelivery = action.payload;
    },

    setOpenOfferDate(state, action) {
      state.isLoading = false;
      state.openOfferDate = action.payload;
    },

    setSubmissionOfferDate(state, action) {
      state.isLoading = false;
      state.submissionOfferDate = action.payload;
    },

    setExpiryOfferDate(state, action) {
      state.isLoading = false;
      state.expiryOfferDate = action.payload;
    },

    setOfferTo(state, action) {
      state.isLoading = false;
      state.offerTo = action.payload;
    },

    setCostingProfileName(state, action) {
      state.isLoading = false;
      state.costingProfileName = action.payload;
    },

    setCostingProfileID(state, action) {
      state.isLoading = false;
      state.costingProfileID = action.payload;
    },

    setBackFromEditProduct(state, action) {
      state.isLoading = false;
      state.backFromEditProduct = action.payload;
    },

    setEditOfferID(state, action) {
      state.isLoading = false;
      state.editOfferID = action.payload;
    },

    resetOffer(state) {
      state.offerSerial = ''
      state.supplierOfferID = ''
      state.supplier = ''
      state.supplierID = ''
      state.expectedDeliveryType = ''
      state.expectedDelivery = ''
      state.openOfferDate = ''
      state.submissionOfferDate = ''
      state.expiryOfferDate = ''
      state.offerTo = ''
      state.offerProducts = []
      state.currAddProducts = []
      state.backFromAddProduct = false
      state.categoryOptions = {
        plush: false,
        figurines: false,
        tcg: false
      }
      state.MOQ = 0
      state.costingProfileID = ''
      state.costingProfileName = ''
      state.costCurrency = ''
      state.costCurrencySymbol = ''
      state.pricingProfileName = ''
      state.pricingProfileID = ''
      state.baseCurrency = ''
      state.baseCurrencySymbol = ''
      state.shippingTerms = ''
      state.paymentTerms = ''
      state.backFromEditProduct = false
      state.editOfferID = ''
      state.tempForEditOfferProducts = []
      state.deleteItemID = []

      state.listSpecificRetailer = []

      state.chosenRetailerGroup = []

      state.offerName = ''

      state.isGashaponOffer = 'no'

      state.pricingDetail = {}
      state.priceOption = ''

      state.isSubDistributorOnlyOffer = 'no'
    },

    setBackFromAddProduct(state, action) {
      state.isLoading = false;
      state.backFromAddProduct = action.payload;
    },

    setCategoryOptions(state, action) {
      state.isLoading = false;
      state.categoryOptions = action.payload;
    },

    setMOQ(state, action) {
      state.isLoading = false;
      state.MOQ = action.payload;
    },

    setCostCurrency(state, action) {
      state.isLoading = false;
      state.costCurrency = action.payload;
    },

    setCostCurrencySymbol(state, action) {
      state.isLoading = false;
      state.costCurrencySymbol = action.payload;
    },

    setPricingProfileName(state, action) {
      state.isLoading = false;
      state.pricingProfileName = action.payload;
    },

    setPricingProfileID(state, action) {
      state.isLoading = false;
      state.pricingProfileID = action.payload;
    },

    setBaseCurrency(state, action) {
      state.isLoading = false;
      state.baseCurrency = action.payload;
    },

    setBaseCurrencySymbol(state, action) {
      state.isLoading = false;
      state.baseCurrencySymbol = action.payload;
    },

    setShippingTerms(state, action) {
      state.isLoading = false;
      state.shippingTerms = action.payload;
    },

    setShippingTermsID(state, action) {
      state.isLoading = false;
      state.shippingTermsID = action.payload;
    },

    setPaymentTerms(state, action) {
      state.isLoading = false;
      state.paymentTerms = action.payload;
    },

    setPaymentTermsID(state, action) {
      state.isLoading = false;
      state.paymentTermsID = action.payload;
    },

    setEditOfferID(state, action) {
      state.isLoading = false;
      state.editOfferID = action.payload;
    },

    setTempForEditOfferProducts(state, action) {
      state.isLoading = false;
      state.tempForEditOfferProducts = action.payload;
    },

    setDeleteItemID(state, action) {
      state.isLoading = false;
      state.deleteItemID = action.payload;
    },

    setListSpecificRetailer(state, action) {
      state.isLoading = false;
      state.listSpecificRetailer = action.payload;
    },

    setChosenRetailerGroup(state, action) {
      state.isLoading = false;
      state.chosenRetailerGroup = action.payload;
    },

    setOfferName(state, action) {
      state.isLoading = false;
      state.offerName = action.payload;
    },

    setIsGashaponOffer(state, action) {
      state.isLoading = false;
      state.isGashaponOffer = action.payload;
    },

    setPricingDetail(state, action) {
      state.isLoading = false;
      state.pricingDetail = action.payload;
    },

    setPriceOption(state, action) {
      state.isLoading = false;
      state.priceOption = action.payload;
    },

    setIsSubDistributorOnlyOffer(state, action) {
      state.isLoading = false;
      state.isSubDistributorOnlyOffer = action.payload;
    }

  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
} = slice.actions;


// ----------------------------------------------------------------------

export function setOfferProducts(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setOfferProducts(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setOfferSerial(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setOfferSerial(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setSupplierOfferID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setSupplierOfferID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setSupplier(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setSupplier(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setSupplierID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setSupplierID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setExpectedDeliveryType(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setExpectedDeliveryType(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setExpectedDelivery(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setExpectedDelivery(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setOpenOfferDate(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setOpenOfferDate(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setSubmissionOfferDate(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setSubmissionOfferDate(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setExpiryOfferDate(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setExpiryOfferDate(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setOfferTo(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setOfferTo(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function resetOffer() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.resetOffer())
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBackFromAddProduct(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBackFromAddProduct(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setCategoryOptions(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setCategoryOptions(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setMOQ(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setMOQ(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  } 
}

export function setCostingProfileName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setCostingProfileName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  } 
}

export function setCostingProfileID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setCostingProfileID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  } 
}

export function setCostCurrency(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setCostCurrency(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setPricingProfileName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPricingProfileName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setPricingProfileID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPricingProfileID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBaseCurrency(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBaseCurrency(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBaseCurrencySymbol(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBaseCurrencySymbol(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setShippingTerms(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setShippingTerms(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setShippingTermsID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setShippingTermsID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setPaymentTerms(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPaymentTerms(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setPaymentTermsID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPaymentTermsID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setCostCurrencySymbol(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setCostCurrencySymbol(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setCurrAddProducts(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setCurrAddProducts(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBackFromEditProduct(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBackFromEditProduct(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setEditOfferID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setEditOfferID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setTempForEditOfferProducts(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setTempForEditOfferProducts(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setDeleteItemID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setDeleteItemID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setListSpecificRetailer(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setListSpecificRetailer(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setChosenRetailerGroup(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setChosenRetailerGroup(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setOfferName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setOfferName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setIsGashaponOffer(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setIsGashaponOffer(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setPricingDetail(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPricingDetail(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setPriceOption(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPriceOption(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setIsSubDistributorOnlyOffer(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setIsSubDistributorOnlyOffer(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}