import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { Redirect } from 'react-router-dom';
import { PATH_PAGE } from 'src/routes/paths';
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

AuthProtectOfferSales.propTypes = {
  children: PropTypes.node
};

const home_url = process.env.REACT_APP_DOMAIN;

function AuthProtectOfferSales({ children }) {
  const { isLoading, isAuthenticated, user } = useAuth();

  let generalAccessList = user.general_access_list

  console.log('AuthProtect')
  console.log(user)
  
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated || !generalAccessList.includes('offersSales')) {
    window.location = `${home_url}/unauthorized`
    return <LoadingScreen />
  }

  return <>{children}</>;
}

export default AuthProtectOfferSales;
