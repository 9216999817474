import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  chosenRetailerID: '',
  chosenRetailerName: '',
  chosenConsignmentSKUOrderID: [], // obj id
  chosenConsignmentSKUOrderSerial: [],
  listConsignmentSKUOrderID: [],
  notes: '',
  listProducts: [],
  salesPDF: []
}

const slice = createSlice({
  name: 'consignmentReport',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setChosenRetailerID(state, action) {
      state.isLoading = false;
      state.chosenRetailerID = action.payload
    },

    setChosenRetailerName(state, action) {
      state.isLoading = false;
      state.chosenRetailerName = action.payload
    },

    setChosenConsignmentSKUOrderID(state, action) {
      state.isLoading = false;
      state.chosenConsignmentSKUOrderID = action.payload
    },

    setChosenConsignmentSKUOrderSerial(state, action) {
      state.isLoading = false;
      state.chosenConsignmentSKUOrderSerial = action.payload
    },

    setListConsignmentSKUOrderID(state, action) {
      state.isLoading = false;
      state.chosenConsignmentSKUOrderID = action.payload
    },

    setNotes(state, action) {
      state.isLoading = false;
      state.notes = action.payload
    },

    setListProducts(state, action) {
      state.isLoading = false;
      state.listProducts = action.payload
    },

    setSalesPDF(state, action) {
      state.isLoading = false;
      state.salesPDF = action.payload
    },

    resetConsignmentReport(state) {
      state.chosenRetailerID = ''
      state.chosenRetailerName = ''
      state.chosenConsignmentSKUOrderID = []
      state.chosenConsignmentSKUOrderSerial = []
      state.listConsignmentSKUOrderID = []
      state.notes = ''
      state.listProducts = []
      state.salesPDF = []
    }

  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
} = slice.actions;


// ----------------------------------------------------------------------
export function setChosenRetailerID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setChosenRetailerID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setChosenRetailerName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setChosenRetailerName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setChosenConsignmentSKUOrderID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setChosenConsignmentSKUOrderID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setChosenConsignmentSKUOrderSerial(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setChosenConsignmentSKUOrderSerial(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setListConsignmentSKUOrderID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setListConsignmentSKUOrderID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setNotes(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setNotes(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setListProducts(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setListProducts(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setSalesPDF(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setSalesPDF(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function resetConsignmentReport() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.resetConsignmentReport())
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}