import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_ADMIN } from 'src/routes/paths';

import HomeIcon from '@material-ui/icons/Home';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';

import { Icon } from '@iconify/react';
import bxHome from '@iconify/icons-bx/bx-home';
import bxsOffer from '@iconify/icons-bx/bxs-offer';
import shippingBox02 from '@iconify/icons-akar-icons/shipping-box-02';
import boxIcon from '@iconify/icons-la/box';

import fileInvoice from '@iconify/icons-la/file-invoice';
import creditCardOutlined from '@iconify/icons-ant-design/credit-card-outlined';
import shopIcon from '@iconify/icons-vs/shop';
import deliveryTruck from '@iconify/icons-carbon/delivery-truck';

import peopleGroup from '@iconify/icons-akar-icons/people-group';
import personIcon from '@iconify/icons-akar-icons/person';
import buildingWarehouse from '@iconify/icons-tabler/building-warehouse';
import shoppingBagOutline from '@iconify/icons-eva/shopping-bag-outline';
import emailIcon from '@iconify/icons-carbon/email';
import reportIcon from '@iconify/icons-carbon/report';
import costingIcon from '@iconify/icons-carbon/calculator-check';

import currencyDollar from '@iconify/icons-carbon/currency-dollar';
import locationIcon from '@iconify/icons-ep/location';
import cubeIcon from '@iconify/icons-carbon/cube';
import parachuteBox from '@iconify/icons-fa-solid/parachute-box';

import documentBlank from '@iconify/icons-carbon/document-blank';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  home: <Icon icon={bxHome} style={{ width: '2em', height: '2em'}}/>,
  offers: <Icon icon={bxsOffer} style={{ width: '2em', height: '2em'}}/>,
  products: <Icon icon={shippingBox02} style={{ width: '2em', height: '2em'}}/>,
  preOrder: <Icon icon={boxIcon} style={{ width: '2em', height: '2em'}}/>,
  purchaseOrder: <Icon icon={shoppingBagOutline} style={{ width: '2em', height: '2em'}}/>,
  order: <Icon icon={fileInvoice} style={{ width: '2em', height: '2em'}}/>,
  payment: <Icon icon={creditCardOutlined} style={{ width: '2em', height: '2em'}}/>,
  invoice: <Icon icon={currencyDollar} style={{ width: '2em', height: '2em'}}/>,
  tracking: <Icon icon={locationIcon} style={{ width: '2em', height: '2em'}}/>,
  shipment: <Icon icon={deliveryTruck} style={{ width: '2em', height: '2em'}}/>,
  retailer: <Icon icon={shopIcon} style={{ width: '2em', height: '2em'}}/>,
  role: <Icon icon={peopleGroup} style={{ width: '2em', height: '2em' }}/>,
  user: <Icon icon={personIcon} style={{ width: '2em', height: '2em'}}/>,
  email: <Icon icon={emailIcon} style={{ width: '2em', height: '2em'}}/>,
  report: <Icon icon={reportIcon} style={{ width: '2em', height: '2em'}}/>,
  costing: <Icon icon={costingIcon} style={{ width: '2em', height: '2em'}}/>,
  inbound: <Icon icon={cubeIcon} style={{ width: '2em', height: '2em'}}/>,
  import: <Icon icon={parachuteBox} style={{ width: '2em', height: '2em'}}/>,
  sku: <Icon icon={documentBlank} style={{ width: '2em', height: '2em' }}/>
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'home',
        icon: ICONS.home,
        href: PATH_ADMIN.general.root
      },
      {
        title: 'user',
        icon: ICONS.user,
        href: PATH_ADMIN.general.user
      }
    ]
  },

  // INVOICING VERSION 3
  /**{
    subheader: 'Invoicing version-3',
    items: [
      {
        title: 'order sales v3',
        icon: ICONS.order,
        href: PATH_ADMIN.version3.orderSales
      },
      {
        title: 'order sales manager v3',
        icon: ICONS.order,
        href: PATH_ADMIN.version3.orderSalesManager
      },
      {
        title: 'order finance v3',
        icon: ICONS.order,
        href: PATH_ADMIN.version3.orderFinance
      },
      {
        title: 'payment finance v3',
        icon: ICONS.payment,
        href: PATH_ADMIN.version3.paymentFinance
      },
      {
        title: 'invoices finance v3',
        icon: ICONS.invoice,
        href: PATH_ADMIN.version3.invoiceFinance
      },
      {
        title: 'invoices finance manager v3',
        icon: ICONS.invoice,
        href: PATH_ADMIN.version3.invoiceFinanceManager
      },
      {
        title: 'invoices sales v3',
        icon: ICONS.invoice,
        href: PATH_ADMIN.version3.invoiceSales
      },
      {
        title: 'tracking sales v3',
        icon: ICONS.tracking,
        href: PATH_ADMIN.version3.trackingSales
      },
      {
        title: 'tracking logistic v3',
        icon: ICONS.tracking,
        href: PATH_ADMIN.version3.trackingLogistic
      }
    ]
  },

  // PROCUREMENT VERSION 3
  {
    subheader: 'Procurement Version 3',
    items: [
      {
        title: 'inbound logistic v3',
        icon: ICONS.inbound,
        href: PATH_ADMIN.version3.inboundLogistic
      },
      {
        title: 'inbound sales v3',
        icon: ICONS.inbound,
        href: PATH_ADMIN.version3.inboundSales
      },
      {
        title: 'import finance v3',
        icon: ICONS.import,
        href: PATH_ADMIN.version3.importFinance
      }
    ]
  },

  // PRE ORDER VERSION 3
  {
    subheader: 'Pre Order Version 3',
    items: [
      {
        title: 'offers sales v3',
        icon: ICONS.offers,
        href: PATH_ADMIN.version3.offerSales
      },
      {
        title: 'offer sales manager v3',
        icon: ICONS.offers,
        href: PATH_ADMIN.version3.offerSalesManager
      },
      {
        title: 'pre-order sales v3',
        icon: ICONS.preOrder,
        href: PATH_ADMIN.version3.preOrderSales
      },
      {
        title: 'pre-order sales manager v3',
        icon: ICONS.preOrder,
        href: PATH_ADMIN.version3.preOrderSalesManager
      },
      {
        title: 'closed offers v3',
        icon: ICONS.offers,
        href: PATH_ADMIN.version3.closedOffersBuyer
      }
    ]
  },

  // PURCHASE ORDER VERSION 3 
  {
    subheader: 'Purchase Order Version 3',
    items: [
      {
        title: 'purchase order buyer v3',
        icon: ICONS.order,
        href: PATH_ADMIN.version3.purchaseOrderBuyer
      }
    ]
  },

  // PRICING VERSION 3
  {
    subheader: 'Pricing Version 3',
    items: [
      {
        title: 'costing',
        icon: ICONS.costing,
        href: PATH_ADMIN.general.costingView
      },
      {
        title: 'sku v3',
        icon: ICONS.sku,
        href: PATH_ADMIN.version3.skuBuyer
      }
    ]
  },*/

  {
    subheader: 'pre-order',
    items: [
      {
        title: 'offers',
        icon: ICONS.offers,
        href: PATH_ADMIN.general.offers
      },
      {
        title: 'products',
        icon: ICONS.products,
        href: PATH_ADMIN.general.products
      },
      {
        title: 'pre-order',
        icon: ICONS.preOrder,
        href: PATH_ADMIN.general.preOrder
      }
    ]
  },
  {
    subheader: 'purchase-order',
    items: [
      {
        title: 'purchase order',
        icon: ICONS.purchaseOrder,
        href: PATH_ADMIN.general.purchaseOrder
      }
    ]
  },
  {
    subheader: 'sales',
    items: [
      {
        title: 'order',
        icon: ICONS.order,
        href: PATH_ADMIN.general.order
      },
      {
        title: 'payment',
        icon: ICONS.payment,
        href: PATH_ADMIN.general.payment
      },
      {
        title: 'shipment',
        icon: ICONS.shipment,
        href: PATH_ADMIN.general.shipment
      }
    ]
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'retailer',
        icon: ICONS.retailer,
        href: PATH_ADMIN.general.retailer
      },
      {
        title: 'supplier',
        icon: ICONS.retailer,
        href: PATH_ADMIN.general.supplier
      },
      {
        title: 'reports',
        icon: ICONS.report,
        href: PATH_ADMIN.general.reports
      }
    ]
  },
  {
    subheader: 'settings',
    items: [
      {
        title: 'email',
        icon: ICONS.email,
        href: PATH_ADMIN.general.email
      }
    ]
  }
];

export default navConfig;
