import { PATH_SALES } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardSalesLayout from 'src/layouts/DashboardSalesLayout';

// ----------------------------------------------------------------------

const AppSalesRoutes = {
  path: PATH_SALES.root,
  guard: AuthProtect,
  layout: DashboardSalesLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // DASHBOARD
    {
      exact: true,
      path: PATH_SALES.general.dashboard,
      component: lazy(() => import('src/views/SalesDashboardGroup/dashboard/DashboardAppView'))
    },

    // PRE-ORDER
    {
      exact: true,
      path: PATH_SALES.general.preOrder,
      component: lazy(() => import('src/views/SalesDashboardGroup/pre-order/PreOrder'))
    },
    {
      exact: true,
      path: PATH_SALES.general.addPreOrder,
      component: lazy(() => import('src/views/SalesDashboardGroup/pre-order/add-pre-order/AddPreOrder'))
    },
    {
      exact: true,
      path: PATH_SALES.general.preOrderDetails.view,
      component: lazy(() => import('src/views/SalesDashboardGroup/pre-order/details/PreOrderView'))
    },

    // OFFERS
    {
      exact: true,
      path: PATH_SALES.general.offers,
      component: lazy(() => import('src/views/SalesDashboardGroup/offers/Offers'))
    },
    {
      exact: true,
      path: PATH_SALES.general.addOffers,
      component: lazy(() => import('src/views/SalesDashboardGroup/offers/add-offers/AddOffers2'))
    },
    {
      exact: true,
      path: PATH_SALES.general.offerDetails.view,
      component: lazy(() => import('src/views/SalesDashboardGroup/offers/details/OfferView'))
    },
    {
      exact: true,
      path: PATH_SALES.general.offerDetails.product,
      component: lazy(() => import('src/views/SalesDashboardGroup/offers/details/OfferViewProduct'))
    },
    {
      exact: true,
      path: PATH_SALES.general.addOffersDetail.addProduct,
      component: lazy(() => import('src/views/SalesDashboardGroup/offers/add-product/AddProduct'))
    },
    {
      exact: true,
      path: PATH_SALES.general.editOffers,
      component: lazy(() => import('src/views/SalesDashboardGroup/offers/edit-offers/EditOffers'))
    },

    // PRODUCTS
    {
      exact: true,
      path: PATH_SALES.general.products,
      component: lazy(() => import('src/views/SalesDashboardGroup/products/Products'))
    },
    {
      exact: true,
      path: PATH_SALES.general.addProduct,
      component: lazy(() => import('src/views/SalesDashboardGroup/products/add-products/AddProduct'))
    },
    {
      exact: true,
      path: PATH_SALES.general.productDetails.view,
      component: lazy(() => import('src/views/SalesDashboardGroup/products/details/ProductView'))
    },

    // ORDER
    {
      exact: true,
      path: PATH_SALES.general.order,
      component: lazy(() => import('src/views/SalesDashboardGroup/invoice/Invoice'))
    },
    {
      exact: true,
      path: PATH_SALES.general.addOrder,
      component: lazy(() => import('src/views/SalesDashboardGroup/invoice/AddInvoice'))
    },
    {
      exact: true,
      path: PATH_SALES.general.orderDetails.view,
      component: lazy(() => import('src/views/SalesDashboardGroup/invoice/details/ViewInvoice'))
    },

    // PAYMENT
    {
      exact: true,
      path: PATH_SALES.general.payment,
      component: lazy(() => import('src/views/SalesDashboardGroup/payment/Payment'))
    },
    {
      exact: true,
      path: PATH_SALES.general.addPayment,
      component: lazy(() => import('src/views/SalesDashboardGroup/payment/add-payment/AddPayment'))
    },
    {
      exact: true,
      path: PATH_SALES.general.paymentDetails.view,
      component: lazy(() => import('src/views/SalesDashboardGroup/payment/PaymentView'))
    },  

    // SHIPMENT
    {
      exact: true,
      path: PATH_SALES.general.shipment,
      component: lazy(() => import('src/views/SalesDashboardGroup/shipment/Shipment'))
    },
    {
      exact: true,
      path: PATH_SALES.general.addShipment,
      component: lazy(() => import('src/views/SalesDashboardGroup/shipment/AddShipment'))
    },
    {
      exact: true,
      path: PATH_SALES.general.shipmentDetails.view,
      component: lazy(() => import('src/views/SalesDashboardGroup/shipment/details/ShipmentView'))
    },

    // RETAILER
    {
      exact: true,
      path: PATH_SALES.general.retailer,
      component: lazy(() => import('src/views/SalesDashboardGroup/retailer/Retailers'))
    },
    {
      exact: true,
      path: PATH_SALES.general.addRetailer,
      component: lazy(() => import('src/views/SalesDashboardGroup/retailer/add-retailer/AddRetailer'))
    },
    {
      exact: true,
      path: PATH_SALES.general.retailerDetails.view,
      component: lazy(() => import('src/views/SalesDashboardGroup/retailer/view-retailer/ViewRetailer'))
    },
    {
      exact: true,
      path: PATH_SALES.general.retailerDetails.edit,
      component: lazy(() => import('src/views/SalesDashboardGroup/retailer/edit-retailer/EditRetailer'))
    },

    // SUPPLIER
    {
      exact: true,
      path: PATH_SALES.general.supplier,
      component: lazy(() => import('src/views/SalesDashboardGroup/supplier/Supplier'))
    },
    {
      exact: true,
      path: PATH_SALES.general.addSupplier,
      component: lazy(() => import('src/views/SalesDashboardGroup/supplier/AddSupplier'))
    },
    {
      exact: true,
      path: PATH_SALES.general.supplierDetails.view,
      component: lazy(() => import('src/views/SalesDashboardGroup/supplier/details/SupplierView'))
    },

    // TAGS
    {
      exact: true,
      path: PATH_SALES.general.tags,
      component: lazy(() => import('src/views/SalesDashboardGroup/tags/Tags'))
    },
    {
      exact: true,
      path: PATH_SALES.general.addTags,
      component: lazy(() => import('src/views/SalesDashboardGroup/tags/AddTag'))
    },
    {
      exact: true,
      path: PATH_SALES.general.tagsDetails.view,
      component: lazy(() => import('src/views/SalesDashboardGroup/tags/details/TagView'))
    },

    // EMAIL
    {
      exact: true,
      path: PATH_SALES.general.email,
      component: lazy(() => import('src/views/SalesDashboardGroup/email/EmailView'))
    }

    // PURCHASE ORDER
    /**{
      exact: true,
      path: PATH_SALES.general.purchaseOrder,
      component: lazy(() => import('src/views/SalesDashboardGroup/purchase-order/PurchaseOrder'))
    },
    {
      exact: true,
      path: PATH_SALES.general.addPurchaseOrder,
      component: lazy(() => import('src/views/SalesDashboardGroup/purchase-order/add-purchase-order/AddPurchaseOrder'))
    }*/
  ]
}

export default AppSalesRoutes;