import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { Redirect } from 'react-router-dom';
// import { PATH_PAGE } from 'src/routes/paths';
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node
};

//const home_url = process.env.REACT_APP_DOMAIN;

function AuthProtect({ children }) {
  const { isLoading, isAuthenticated, user } = useAuth();
  //const { pathname } = useLocation();

  //console.log('AuthProtect')
  //console.log(pathname)
  //console.log(user)
  
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  // if (!isAuthenticated) {
  //   window.location = home_url
  //   return <LoadingScreen />;
  //   //return <Redirect to={PATH_PAGE.auth.login} />;
  // }

  return <>{children}</>;
}

export default AuthProtect;
