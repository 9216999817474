// ----------------------------------------------------------------------

export default function Button({ theme }) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            // boxShadow: 'none',
            //background: theme.palette.primary.main
          }
        },
        sizeLarge: {
          height: 48
        },
        containedInherit: {
          boxShadow: 'none !important',
          '&:hover': {
            boxShadow: `${theme.shadows[25].z8} !important`,
            backgroundColor: theme.palette.grey[400]
          }
        },
        containedPrimary: {
          boxShadow: 'none !important',
          '&:hover': {
            boxShadow: `${theme.shadows[25].primary} !important`
          }
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          boxShadow: 'none !important',
          '&:hover': {
            boxShadow: 'none !important',
            backgroundColor: theme.palette.action.hover
          }
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }
      }
    }
  };
}
