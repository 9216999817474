import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import authJwtReducer from './slices/authJwt';
import settingsReducer from './slices/settings';
import calendarReducer from './slices/calendar';
import notificationsReducer from './slices/notifications';

import authFirebaseReducer from './slices/authFirebase';

import offerReducer from './slices/offer';
import pricingReducer from './slices/pricing';
import consignmentSKUOrderReducer from './slices/consignmentSKUOrder';
import consignmentStockReturnableReducer from './slices/consignmentStockReturnable';
import consignmentReportReducer from './slices/consignmentReport';

import generateProductCatalogueReducer from './slices/generateProductCatalogue';
import generateConsignmentProductCatalogueReducer from './slices/generateConsignmentProductCatalogue';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const productPersistConfig = {
  key: 'product',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const authPersistConfig = {
  key: 'authJwt',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const authPersistConfigFirebase = {
  key: 'authFirebase',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated', 'user']
}

const offerPersistConfig = {
  key: 'offer',
  storage: storage,
  keyPrefix: 'redux-'
}

const pricingPersistConfig = {
  key: 'pricing',
  storage: storage,
  keyPrefix: 'redux-'
}

const consignmentSKUOrderPersistConfig = {
  key: 'consignmentSKUOrder',
  storage: storage,
  keyPrefix: 'redux-'
}

const consignmentStockReturnablePersistConfig = {
  key: 'consignmentStockReturnable',
  storage: storage,
  keyPrefix: 'redux-'
}

const consignmentReportPersistConfig = {
  key: 'consignmentReport',
  storage: storage,
  keyPrefix: 'redux-'
}

const generateProductCataloguePersistConfig = {
  key: 'generateProductCatalogue',
  storage: storage,
  keyPrefix: 'redux-'
}

const generateConsignmentProductCaloguePersistConfig = {
  key: 'generateConsignmentProductCatalogue',
  storage: storage,
  keyPrefix: 'redux-'
}

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  //firestore: firestoreReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  settings: settingsReducer,
  calendar: calendarReducer,
  notifications: notificationsReducer,
  product: persistReducer(productPersistConfig, productReducer),
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  authFirebase: persistReducer(authPersistConfigFirebase, authFirebaseReducer),
  offer: persistReducer(offerPersistConfig, offerReducer),
  pricing: persistReducer(pricingPersistConfig, pricingReducer),
  consignmentSKUOrder: persistReducer(consignmentSKUOrderPersistConfig, consignmentSKUOrderReducer),
  consignmentStockReturnable: persistReducer(consignmentStockReturnablePersistConfig, consignmentStockReturnableReducer),
  consignmentReport: persistReducer(consignmentReportPersistConfig, consignmentReportReducer),

  generateProductCatalogue: persistReducer(generateProductCataloguePersistConfig, generateProductCatalogueReducer),
  generateConsignmentProductCatalogue: persistReducer(generateConsignmentProductCaloguePersistConfig, generateConsignmentProductCatalogueReducer)
});

export { rootPersistConfig, rootReducer };
