import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_FINANCE } from 'src/routes/paths';

import HomeIcon from '@material-ui/icons/Home';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';

import { Icon } from '@iconify/react';
import bxHome from '@iconify/icons-bx/bx-home';
import fileInvoice from '@iconify/icons-la/file-invoice';
import creditCardOutlined from '@iconify/icons-ant-design/credit-card-outlined';
import deliveryTruck from '@iconify/icons-carbon/delivery-truck';
import emailIcon from '@iconify/icons-carbon/email';
import reportIcon from '@iconify/icons-carbon/report';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  home: <Icon icon={bxHome} style={{ width: '2em', height: '2em'}}/>,
  order: <Icon icon={fileInvoice} style={{ width: '2em', height: '2em'}}/>,
  payment: <Icon icon={creditCardOutlined} style={{ width: '2em', height: '2em'}}/>,
  shipment: <Icon icon={deliveryTruck} style={{ width: '2em', height: '2em'}}/>,
  email: <Icon icon={emailIcon} style={{ width: '2em', height: '2em'}}/>,
  report: <Icon icon={reportIcon} style={{ width: '2em', height: '2em'}}/>
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'home',
        icon: ICONS.home,
        href: PATH_FINANCE.general.root
      }
    ]
  },
  {
    subheader: 'sales',
    items: [
      {
        title: 'order',
        icon: ICONS.order,
        href: PATH_FINANCE.general.order
      },
      {
        title: 'payment',
        icon: ICONS.payment,
        href: PATH_FINANCE.general.payment
      },
      {
        title: 'shipment',
        icon: ICONS.shipment,
        href: PATH_FINANCE.general.shipment
      },
      {
        title: 'report',
        icon: ICONS.report,
        href: PATH_FINANCE.general.reports
      }
    ]
  },
  {
    subheader: 'settings',
    items: [ 
      {
        title: 'email',
        icon: ICONS.email,
        href: PATH_FINANCE.general.email
      }
    ]
  }
];

export default navConfig;
