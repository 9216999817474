import { PATH_LOGISTIC } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLogisticLayout from 'src/layouts/DashboardLogisticLayout';

// ----------------------------------------------------------------------
const AppLogisticRoutes = {
  path: PATH_LOGISTIC.root,
  guard: AuthProtect,
  layout: DashboardLogisticLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // DASHBOARD
    {
      exact: true,
      path: PATH_LOGISTIC.general.dashboard,
      component: lazy(() => import('src/views/LogisticDashboardGroup/dashboard/DashboardAppView'))
    },

    // ORDER
    {
      exact: true,
      path: PATH_LOGISTIC.general.invoice,
      component: lazy(() => import('src/views/LogisticDashboardGroup/invoice/Invoice'))
    },
    {
      exact: true,
      path: PATH_LOGISTIC.general.invoiceDetails.view,
      component: lazy(() => import('src/views/LogisticDashboardGroup/invoice/details/InvoiceView'))
    },

    // RMA
    {
      exact: true,
      path: PATH_LOGISTIC.general.rma,
      component: lazy(() => import('src/views/LogisticDashboardGroup/rma/RMA'))
    },
    {
      exact: true,
      path: PATH_LOGISTIC.general.addRma,
      component: lazy(() => import('src/views/LogisticDashboardGroup/rma/AddRMA'))
    },
    {
      exact: true,
      path: PATH_LOGISTIC.general.rmaDetails.edit,
      component: lazy(() => import('src/views/LogisticDashboardGroup/rma/rma-details/EditRMA'))
    },

    // SHIPMENT
    {
      exact: true,
      path: PATH_LOGISTIC.general.shipment,
      component: lazy(() => import('src/views/LogisticDashboardGroup/shipment/Shipment'))
    },
    {
      exact: true,
      path: PATH_LOGISTIC.general.addShipment,
      component: lazy(() => import('src/views/LogisticDashboardGroup/shipment/AddShipment'))
    },
    {
      exact: true,
      path: PATH_LOGISTIC.general.shipmentDetails.view,
      component: lazy(() => import('src/views/LogisticDashboardGroup/shipment/details/ShipmentView'))
    },

    // EMAIL
    {
      exact: true,
      path: PATH_LOGISTIC.general.email,
      component: lazy(() => import('src/views/LogisticDashboardGroup/email/EmailView'))
    }
  ]
}

export default AppLogisticRoutes