import { PATH_ADMIN, PATH_ADMIN_REGISTER } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardAdminRegisterLayout from 'src/layouts/DashboardAdminRegisterLayout';

// ----------------------------------------------------------------------

const AppAdminRegisterRoutes = {
  path: PATH_ADMIN_REGISTER.root,
  guard: AuthProtect,
  layout: DashboardAdminRegisterLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // DISTRIBUTOR
    {
      exact: true,
      path: PATH_ADMIN_REGISTER.general.distributor,
      component: lazy(() => import('src/views/AdminRegisterDashboardGroup/distributor/DistributorForm'))
    },
    {
      exact: true,
      path: PATH_ADMIN_REGISTER.general.distributorSubmitted,
      component: lazy(() => import('src/views/AdminRegisterDashboardGroup/distributor/DistributorFormSubmitted'))
    },
    {
      exact: true,
      path: PATH_ADMIN_REGISTER.general.distributorRejected.detail,
      component: lazy(() => import('src/views/AdminRegisterDashboardGroup/distributor/DistributorRejected'))
    },
    {
      exact: true,
      path: PATH_ADMIN_REGISTER.general.distributorRejected.new,
      component: lazy(() => import('src/views/AdminRegisterDashboardGroup/distributor/DistributorRejectedNewForm'))
    }
  ]
}

export default AppAdminRegisterRoutes