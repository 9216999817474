import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_SALES } from 'src/routes/paths';

import HomeIcon from '@material-ui/icons/Home';

import { Icon } from '@iconify/react';
import bxHome from '@iconify/icons-bx/bx-home';
import boxIcon from '@iconify/icons-la/box';
import bxsOffer from '@iconify/icons-bx/bxs-offer';
import shippingBox02 from '@iconify/icons-akar-icons/shipping-box-02';
import fileInvoice from '@iconify/icons-la/file-invoice';
import creditCardOutlined from '@iconify/icons-ant-design/credit-card-outlined';
import shopIcon from '@iconify/icons-vs/shop';
import shoppingBagOutline from '@iconify/icons-eva/shopping-bag-outline';
import peopleGroup from '@iconify/icons-akar-icons/people-group';
import tagsIcon from '@iconify/icons-cil/tags';
import deliveryTruck from '@iconify/icons-carbon/delivery-truck';
import emailIcon from '@iconify/icons-carbon/email';


// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  home: <Icon icon={bxHome} style={{ width: '2em', height: '2em'}}/>,
  preOrder: <Icon icon={boxIcon} style={{ width: '2em', height: '2em'}}/>,
  offers: <Icon icon={bxsOffer} style={{ width: '2em', height: '2em'}}/>,
  products: <Icon icon={shippingBox02} style={{ width: '2em', height: '2em'}}/>,
  order: <Icon icon={fileInvoice} style={{ width: '2em', height: '2em'}}/>,
  payment: <Icon icon={creditCardOutlined} style={{ width: '2em', height: '2em'}}/>,
  shipment: <Icon icon={deliveryTruck} style={{ width: '2em', height: '2em'}}/>,
  retailer: <Icon icon={shopIcon} style={{ width: '2em', height: '2em'}}/>,
  tags: <Icon icon={tagsIcon} style={{ width: '2em', height: '2em'}}/>,
  purchaseOrder: <Icon icon={shoppingBagOutline} style={{ width: '2em', height: '2em'}}/>,
  role: <Icon icon={peopleGroup} style={{ width: '2em', height: '2em' }}/>,
  email: <Icon icon={emailIcon} style={{ width: '2em', height: '2em'}}/>
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'home',
        icon: ICONS.home,
        href: PATH_SALES.general.root
      }]
  },
  {
    subheader: 'Pre-Order',
    items: [
      {
        title: 'Offers',
        icon: ICONS.offers,
        href: PATH_SALES.general.offers
      },
      {
        title: 'products',
        icon: ICONS.products,
        href: PATH_SALES.general.products
      },
      {
        title: 'Pre-Order',
        icon: ICONS.preOrder,
        href: PATH_SALES.general.preOrder
      },
      //comment due to purchase order is on admin sales - ppt refer
      /**{
        title: 'purchase order',
        icon: ICONS.purchaseOrder,
        href: PATH_SALES.general.purchaseOrder
      }*/
      ]
  },
  {
    subheader: 'sales',
    items: [  
      {
        title: 'order',
        icon: ICONS.order,
        href: PATH_SALES.general.order
      },
      {
        title: 'payment',
        icon: ICONS.payment,
        href: PATH_SALES.general.payment
      },
      {
        title: 'shipment',
        icon: ICONS.shipment,
        href: PATH_SALES.general.shipment
      }
    ]
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'retailer',
        icon: ICONS.retailer,
        href: PATH_SALES.general.retailer
      },
      {
        title: 'supplier',
        icon: ICONS.retailer,
        href: PATH_SALES.general.supplier
      }
    ]
  },
  {
    subheader: 'setting',
    items: [
      {
        title: 'email',
        icon: ICONS.email,
        href: PATH_SALES.general.email
      }
    ]
  }
];

export default navConfig;
