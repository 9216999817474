import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { login, register, logout } from 'src/redux/slices/authJwt';
import {
  isEmpty,
  isLoaded,
  useFirebase,
  useFirestore
} from 'react-redux-firebase';
import _ from 'lodash';
const home_url = process.env.REACT_APP_DOMAIN

// ----------------------------------------------------------------------

useAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase'])
};

export default function useAuth(method = 'firebase') {
  // Firebase Auth
  const firebase = useFirebase();
  const firestore = useFirestore();
  const { auth, profile } = useSelector((state) => state.firebase);

  // JWT Auth
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state) => state.authFirebase
  );

  // JWT Auth
  /**if (method === 'jwt') {
    return {
      method: 'jwt',
      user: user,
      isLoading: isLoading,
      isAuthenticated: isAuthenticated,

      login: ({ email, password }) =>
        dispatch(
          login({
            email: email,
            password: password
          })
        ),

      register: ({ email, password, firstName, lastName }) =>
        dispatch(
          register({
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName
          })
        ),

      logout: () => dispatch(logout()),

      resetPassword: () => {},

      updateProfile: () => {}
    };
  }*/

  // Firebase Auth
  return {
    method: 'firebase',
    user: user,
    isLoading: !isLoaded(auth),
    isAuthenticated: !isEmpty(auth),

    login: ({ email, password }) =>
      firebase.login({
        email: email,
        password: password
      }),

    loginWithToken: (token) => {
      firebase.login({token: token})
    },

    loginWithGoogle: () =>
      firebase.login({ provider: 'google', type: 'popup' }),

    loginWithFaceBook: () =>
      firebase.login({ provider: 'facebook', type: 'popup' }),

    loginWithTwitter: () =>
      firebase.login({ provider: 'twitter', type: 'popup' }),

    register: ({ email, password, firstName, lastName }) =>
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((res) => {
          firestore
            .collection('users')
            .doc(res.user.uid)
            .set({
              uid: res.user.uid,
              email: email,
              displayName: firstName + ' ' + lastName
            });
        }),

    logout: async () => { 
      await firebase.auth().signOut() 
    },

    logoutSubDistributor: async () => {
      await firebase.auth().signOut()
      .then(() => {
        console.log('User signed out successfully');
        window.location.href = home_url;
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
    },

    logoutWithToken: (token) => {
      firebase.logout({token: token})
    },
    
    resetPassword: (email) => firebase.resetPassword(email),

    updateProfile: ({
      displayName,
      photoURL,
      phoneNumber,
      country,
      state,
      city,
      address,
      zipCode,
      about,
      isPublic
    }) =>
      firebase.updateProfile({}).then((res) => {
        firestore.collection('users').doc(res.id).set(
          {
            displayName: displayName,
            photoURL: photoURL,
            phoneNumber: phoneNumber,
            country: country,
            state: state,
            city: city,
            address: address,
            zipCode: zipCode,
            about: about,
            isPublic: isPublic
          },
          { merge: true }
        );
      })
  };
}
