import { PATH_ADMIN, PATH_ADMIN_KC } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardAdminKCLayout from 'src/layouts/DashboardAdminKCLayout';

// ----------------------------------------------------------------------

const AppAdminKCRoutes = {
  path: PATH_ADMIN_KC.root,
  guard: AuthProtect,
  layout: DashboardAdminKCLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // DASHBOARD
    {
      exact: true,
      path: PATH_ADMIN_KC.general.dashboard,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/dashboard/DashboardAppView'))
    },

    // NOTIFICATION
    {
      exact: true,
      path: PATH_ADMIN_KC.general.notifications,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/notifications/Notifications'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.notificationDetail.view,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/notifications/detail/NotificationView'))
    },

    // DISTRIBUTOR
    {
      exact: true,
      path: PATH_ADMIN_KC.general.distributor,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/distributor/Distributor'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.addDistributor,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/distributor/AddDistributorNew'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.distributorDetails.view,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/distributor/details/DistributorView'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.distributorDetails.moduleSetting,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/distributor/details/DistributorModuleSetting'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.distributorDetails.subdistributorSetting,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/distributor/details/DistributorSubdistributorSetting'))
    },

    // RETAILER
    {
      exact: true,
      path: PATH_ADMIN_KC.general.retailer,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/retailer/Retailer'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.retailerDetails.view,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/retailer/details/RetailerView'))
    },

    // BRANCH
    {
      exact: true,
      path: PATH_ADMIN_KC.general.branch,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/branch/Branch'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.branchDetails.view,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/branch/details/BranchView'))
    },

    // USER
    {
      exact: true,
      path: PATH_ADMIN_KC.general.user,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/user/User'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.addUser,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/user/AddUser'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.userDetails.view,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/user/ViewUser'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.userDetails.emailNotificationSetting,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/user/EmailNotificationSetting'))
    },

    // ROLE
    {
      exact: true,
      path: PATH_ADMIN_KC.general.role,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/role/Role'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.addRole,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/role/AddRole'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.roleDetails.view,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/role/RoleView'))
    },

    // CURRENCY
    {
      exact: true,
      path: PATH_ADMIN_KC.general.currency,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/currency/Currency'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.addCurrency,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/currency/AddCurrency'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.currencyDetails.view,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/currency/details/CurrencyView'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.currencyDetails.edit,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/currency/details/CurrencyEdit'))
    }
  ]
}

export default AppAdminKCRoutes